@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-slideshow-image/dist/styles.css";

.each-slide {
  display: flex;
  width: 100%;
  height: 300px;
}

.each-slide>div {
  width: 100%;
}

.each-slide>div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-slide button {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #3E3E91;
  -webkit-box-shadow: -4px 6px 15px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: -4px 6px 15px -6px rgba(0,0,0,0.75);
  box-shadow: -4px 6px 15px -6px rgba(0,0,0,0.75);  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000000;
  font-weight: 300;
  font-size: larger;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1f2937;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-wpp {
  display: inline-block;
  position: relative;
  color: #FFFFFF;
}

.hover-underline-wpp:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-wpp:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.swiper-slide .slide-title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide .img-slide {
  max-width: 100%;
  max-height: 50%;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}




/* Adapte os estilos conforme necessário */
.thumbnail {
  cursor: pointer;
}

.photo-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
}

.photo-modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 18px;
  color: white;
}
